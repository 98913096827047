.dropdown-options {
  .dropdown-menu {
    border: none !important;
    box-shadow: -2px 3px 10px 1px rgba(0, 0, 0, 0.15);

    a {
      &:hover,
      &:active,
      &:focus {
        background-color: $light !important;
      }
    }

    .dropdown-item {
      &:disabled {
        cursor: not-allowed;
        background-color: red !important;
      }
    }
  }

  button {
    border: none !important;
    font-size: 1rem;
    text-align: center;
    background-color: transparent !important;
    color: $black !important;
    box-shadow: none !important;
  }

  .dropdown-item-menu {
    width: 0;
    height: 0;
    padding: 5px 0 0 0;
  }
}

.nested-dropdown {
  button {
    border-radius: 0 !important;
    border: none !important;
    padding-left: 20px !important;
    color: $black !important;
    width: 100%;
    position: relative;
    font-weight: normal;
    text-align: left;

    &:hover,
    &:active,
    &:focus {
      background-color: $light !important;
    }
  }

  .dropdown-menu {
    //transform: translate3d(150px, 0px, 0px);

    //background-color: red;
    float: left !important; ////
    //margin-top: -15px !important;
    //margin-right: 150px !important;

    .dropdown-item {
      &:hover,
      &:active,
      &:focus {
        background-color: $light;
      }
    }
  }
}

.dropdown-options {
  &.text-white {
    button {
      color: $white !important;
    }
  }
}

.dropdown-options button {
  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
}

.dropdown-btn-opt {
  padding-left: 15px;
  padding-right: 20px;
  box-shadow: none !important;
}

.points-menu {
  button {
    font-size: 16px;
    line-height: 1;
    border: 0;
    border-radius: 5px;
    height: 34px;
    background: url("../../icons/more_vert-24px.svg") no-repeat right;
    -webkit-appearance: none;
    color: transparent !important;
  }
}