.personal-area-container {
  background-color: $white;
  min-height: 100vh;
  height: 100%;

  .head {
    background-color: $blue-dark;
    height: 213px;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 95px;
      height: 95px;
      border-radius: 50%;
      border: 3px solid $white;
      cursor: pointer;
      object-fit: cover;
    }

    .user-name {
      font-weight: 600;
      margin-top: 15px;
      text-align: center;
    }

    .icon-person {
      fill: $dark;
      background-color: $light;
      border-radius: 50%;
      width: 95px;
      height: auto;
      border: 3px solid $white;
    }
  }

  .actions-section {
    text-align: center;
    .icon {

      &.icon-telephone, &.icon-card, &.icon-car {
        width: 36px;
      }
    }
  }

  .section-content {
  }
}

.input-file {
  .label-file {
    font-size: 1.25rem;
    font-weight: 600;
  }
  .form-control-file {
    font-size: 1rem;
  }
}

.btn-user-img {
  width: 50%;
  margin-bottom: 25px;
}