// Responsive typography
html {
	//font-size: .85rem;
	font-size: 65%;
	@include media-breakpoint-up(sm) {
		font-size: .75rem;
	}
	@include media-breakpoint-up(md) {
		font-size: .8rem;
	}
	@include media-breakpoint-up(lg) {
		font-size: .85rem;
	}
	overflow-x: hidden;
	min-height: 100%;
}
