.pager-container {
  display: inline-flex;
  width: 100%;
  margin-top: 35px;
  margin-right: 35px;

  .icon-container {
    cursor: pointer;

    .circle {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: inline-block;
      background-color: lighten($dark, 10);
      position: relative;
    }
    .icon {
      position: absolute;
      height: 24px;
      width: auto;
      fill: $white;
      margin-left: -25px;
    }

    &.disabled {
      opacity: 0.4;
      cursor: auto;
    }
  }

  .points-container {
    display: inline-flex;
    width: 100%;
    margin-top: -4px;
    align-self: center;
    justify-content: center;

    .point {
      width: 12px;
      height: 12px;
      border: 2px solid $primary;
      border-radius: 50%;
      margin-left: 5px;
      margin-right: 5px;

      &.selected {
        background-color: $primary;
      }
    }
  }
}