.ticket-container {
  width: 100%;
  min-height: 100vh;
  background-color: $white;
  padding-top: 20px;
  position: relative;

  @media (max-height: 200px) and (orientation: landscape) {
    min-height: 350vh;
  }
  @media (min-height: 200px) and (orientation: landscape) {
    min-height: 300vh;
  }
  @media (min-height: 290px) and (orientation: landscape) {
    min-height: 220vh;
  }
  @media (min-height: 420px) and (orientation: landscape) {
    min-height: 150vh;
  }
  @media (min-height: 600px) and (orientation: landscape) {
    min-height: 100vh;
  }
}

.ticket-card {
  margin-left: 30px;
  margin-right: 30px;

  &.card-mode {
    height: 110px;
    background-color: lighten($secondary, 39);
    border-radius: 10px;
    padding: 25px;
  }

  &.modal-mode {
    min-width: 60vw;
    margin-right: 10px;
    margin-left: 40px;
    height: 240px !important;

    @media (min-width: 650px) {
      min-width: 30vw;
    }
  }

  .title-description {
    color: $secondary;
    text-align: left;
  }

  .title-name {
    text-align: left;
    color: $black;
    font-weight: 700;
    margin-top: 20px;
  }

  &.data {
    height: 295px;
    margin-top: 55px;
    padding-right: 60px;
    padding-top: 55px;

    @media (orientation: landscape) {
      height: 320px;
    }

    .input-range {
      position: relative;

      .track {
        height: 9px;
        cursor: pointer;
        background: $primary;
        border-radius: 25px;
        margin-right: -31px;

        &.sm {
          height: 6px;
        }
      }

      .slider-container {
        .range-indicator {
          .label-container {
            display: block;
            color: $white;
            font-weight: 700;
            transform: rotate(-45deg) !important;
            margin-top: 15px;
            font-size: 1.15rem;
            margin-left: 1px;
            clear: both;
            white-space: nowrap;

            &.sm {
              font-size: 0.90rem;
              margin-left: 5px;
              margin-top: 8px;
            }
          }
        }
      }

      .slider-pointer {
        box-shadow: 1px 1px 1px $secondary;
        border: 1px solid $secondary;
        height: 32px;
        width: 32px;
        border-radius: 25px;
        background: $white;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -11px;
        position: relative;

        &.sm {
          height: 22px;
          width: 22px;
          margin-top: -9px;
        }
      }

      .max-label {
        color: $secondary;
        float: right;
        margin-right: -31px;
        margin-top: 15px;
      }

      .min-label {
        color: $secondary;
        float: left;
        margin-top: 20px;
      }

      .input-range {
        width: 100%;
      }

      .range-indicator {
        position: absolute;
        margin-top: -85px;
        background-color: $primary;
        width: 50px;
        height: 50px;
        transform: rotate(45deg);
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-left-radius: 50%;
        margin-left: -6px;
        left: 0;

        &.sm {
          margin-top: -55px;
          width: 35px;
          height: 35px;
        }
      }

      .form-control-range {
        margin-top: 25px;

      }
    }

    .price {
      margin-top: 45px;
      color: $primary;
      font-size: 2.5rem;
      font-weight: 700;

      &.sm {
        font-size: 2.2rem;
        text-align: center;
      }
    }

    .end-time {
      margin-top: 20px;
      color: $black;

      .time {
        font-size: 1.5rem;
        font-weight: 600;

        &.sm {
          font-size: 1.1rem;
          text-align: center;
        }
      }
    }

    .expenses {
      margin-top: 35px;
      color: $secondary;
    }
  }

}


