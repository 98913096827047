// Color utilities
.primary-color {color:$primary;}
.secondary-color {color:$secondary !important;}
.success-color {color:$success;}
.info-color {color:$info;}
.warning-color {color:$warning;}
.danger-color {color:$danger;}
.dark-color {color:$dark;}
.light-color {color:$light;}
.white-color {color:$white;}
.accent-color {color: $accent;}

.secondary-light-color {color: lighten($secondary, 15)}

//.social-gray-color {color: darken($white, 26);}