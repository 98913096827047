.full-screen {
  &.modal {
    .modal-dialog {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
    }

    .modal-content {
      height: auto;
      min-height: 100%;
      border-radius: 0;
    }
  }
}

.modal {
  text-align: center;
  padding: 0 !important;

  .modal-content {
    border-radius: 20px;
  }
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.modal-dark {
  color: $white;
  .modal-content {
    background-color: $dark !important;
  }
}

.modal-header {
  padding-top: 20px;
  padding-left: 35px;
  margin-left: 0 !important;
  border-bottom: none !important;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;

  .modal-title {
    padding-top: 15px;
    flex: 0 0 auto;
    align-self: flex-start;
    order: 1;
    margin: 0 auto;

    .icon-header {
      fill: $primary;
      display: block;
      margin: 0 auto;
    }
  }

  .close {
    padding: 0;
    text-align: left;
    width: 100%;
    opacity: 1;

    > span {
      font-size: 4.5rem;
      color: $primary;
      margin-left: -10px !important;
    }

    &:focus {
      outline: none;
    }
  }
}

.modal-back-header {
  background-color: $black;
  height: 5.40rem;

  &.white {
    background-color: $white;
    h4 {
      color: $black !important;
    }
    border-bottom: 3px solid $gray-400 !important;
  }

  .close {
    margin: 0 25px 0 0;
    width: 32px;
  }

  .icon {
    width: 32px;
    height: auto;
    fill: $primary;
    margin-top: -5px;

    &.icon-info {
      width: 30px;
      position: absolute;
      right: 0;
      margin-right: 35px;
    }
  }

  .modal-title {
    padding-top: 0;

    > h4 {
      color: $white;
    }
  }
}

.modal-subtitle {
  background-color: transparent;
  font-family: 'Montserrat';
  font-size: 1.15rem;
  font-weight: normal;
  padding-bottom: 2.3rem;
  text-align: center;
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
}

.modal-body {
  margin-right: 25px !important;
  margin-left: 25px !important;

  h4 {
    margin-top: 2rem;
  }

  .checkbox-title {
    margin-bottom: -3px;
  }

  .checkbox-column {
    display: flex;
    flex-direction: column;
  }
}

.modal-footer {
  border-top: none !important;
  justify-content: center;
}

.modal-medium {
  max-width: 600px;
}

.add-car {
  .modal-content {
    padding-left: 25px;
    padding-right: 25px;
  }

  .label-license {
    font-size: 1.35rem;
    margin-top: -3px;
    font-weight: 400;
    margin-left: 15px;
  }


  .icon-container {
    margin-top: 75px;
  }

  .icon {
    background-color: $secondary;
    fill: $white;
    width: 48px;
    height: auto;
    border-radius: 5px;
  }

  .icon-right {
    width: 36px;
    height: auto;
  }

  .mobility-title {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .mobility-text {
    color: $secondary;
  }

  .mobility-info {
    color: $primary;
    font-weight: 700;
  }

}

.footer-list-vehicle {
  margin-top: 50px;
  @media (max-height: 600px) and (orientation: landscape) {
    margin-top: 60px;
  }
}

/** Modal Panel: Right **/

$modal-height: 100vh;

.modal {
  &.modal-left-pane,
  &.modal-right-pane {
    .modal-dialog {
      min-height: $modal-height;
    }

    &.show .modal-dialog {
      transform: translate(0, 0);
    }

    .modal-content {
      min-height: $modal-height;
    }
  }

  &.modal-left-pane {
    .modal-dialog {
      transform: translate(-100%, 0);
    }
  }

  &.modal-right-pane {
    .modal-dialog {
      transform: translate(100%, 0);
    }
  }
}