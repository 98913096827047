*,
body {
  background-color: transparent;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  //margin: 0 auto !important;
}

body {
  color: $dark;
  font-size: 1.3rem;
}

html {
  background-color: $light;
}

h1, h2, h3, h4, h5, h6 {
  background-color: transparent;
  color: $dark;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 1.4;
}

h1 {
  font-size: 2.9rem;
}

h2 {
  font-size: 2.275rem;
}

h3 {
  font-size: 1.775rem;
}

h4 {
  font-size: 1.4rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1.125rem;
}

p {
  //font-size: 1rem;
  line-height: 1.5;
}

html, body {
  height: 100%;
}

/* --------------------------------------
General Styles
-------------------------------------- */

a {
  -webkit-transition: all .3s ease;
  color: $primary;
  transition: all .3s ease;
  text-decoration: none !important;

  &:hover {
    color: darken($primary, 15%)
  }

  a:hover,
  a:focus,
  a:visited {
    outline: none !important;
    text-decoration: none !important
  }

  input {
    outline: none !important;
  }

  button {
    outline: none !important;
  }
}

/* --------------------------------------
Buttons
-------------------------------------- */
.btn-link {
  text-decoration: none !important;

  &:hover {
    color: darken($primary, 15%);
  }
}

.btn {
  font-weight: 600;
  border-radius: 0;
  font-size: 1.35rem !important;

  &:focus {
    box-shadow: none;
  }

  &:disabled {
    background-color: lighten($success, 35) !important;
  }
}

.btn-bottom {
  position: absolute;
  bottom: 0;
  border-radius: 0 !important;
  color: $white;
  left: 0;
  height: 60px;
}

.btn-primary {
}

.btn-link {
  color: $primary;
  font-weight: 700;
  font-size: 1.3rem;
}

.btn-primary-outline {
}

.btn-secondary-outline {
}

.btn-header-primary {
  border: 2px solid $black;
  font-size: 1rem;
  text-align: center;
  margin-right: 15px;

  &:hover {
    background-color: $black;
    color: $light;
  }
}

.btn-header-secondary {
  font-size: 0.75rem;
  text-align: center;
  background-color: $primary;
  color: $white;
  height: 80% !important;

  &:hover {
    color: $white;
    background-color: $secondary;
  }
}

.btn-lg {
  height: 45px;
}

.btn-list {
  height: 60px;
}

.btn-slim {
  height: 45px;
}

.btn-submit {
  background-color: $success !important;
  width: 100%;

  &:disabled {
    background-color: $success !important;

    &:hover {
      background-color: darken($success, 5%) !important;
    }
  }

  &:hover {
    color: $white;
    background-color: darken($success, 5%) !important;
    border: 1px solid transparent;
  }
}

.btn-secondary {
  color: $primary !important;
  background-color: $white !important;
  width: 100%;
  border: 2px solid $primary;

  &:hover {
    border: 2px solid $primary;
    background-color: $secondary !important;
  }

  &:focus {
    border: 2px solid $primary;
  }
}

.btn-secondary-dark {
  background-color: transparent !important;
}

.btn-tab {
  width: 100%;
  border-top: none;
  border-right: none;
  color: lighten($secondary, 15%);
  border-bottom: 5px solid lighten($secondary, 15%);
  border-left: none;
  padding: 0;
  text-align: left;
  outline: none !important;
  font-size: $h5-font-size;

  &.selected {
    color: $primary;
    border-bottom: 5px solid $primary !important;
  }
}

.cta {
  background-color: $accent;
  border-color: $accent;

  &:hover {
    background-color: darken($accent, 10%);
    border-color: darken($accent, 10%);

  }
}

.pagination {
  font-size: .85rem;

  .page-item {
    .page-link {
      -ms-flex-align: center;
      -ms-flex-item-align: center;
      -ms-flex-pack: center;
      -webkit-box-align: center;
      -webkit-box-pack: center;
      align-items: center;
      align-self: center;
      background-color: transparent;
      border: none;
      border-radius: 50px;
      color: $primary;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 2.5rem;
      justify-content: center;
      text-align: center;
      width: 2.5rem;

      &:hover, &:active, &:focus {
        font-weight: bolder;
        border: .125rem solid $primary;
      }
    }
  }
}

.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.enumeration-card {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  text-align: center;
  color: $white;
  background-color: $accent;
  position: relative;
  top: -8px;
  right: -4px;
  z-index: 1;
}

.card-body {
  background-color: $white;
}

.medium-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.light-background {
  background-color: $white !important;
}

.transparent-background {
  background-color: transparent !important;
}

.container {
  padding: 0;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.border-bg {
  background-image: url("../../img/wave-border.svg");
  background-repeat: repeat-x;
  height: 36px;
  margin-top: -15px;
}

.input-select {
  padding-left: 0;
  font-size: 1.35rem;
  font-weight: bold;
  background-color: #f0f0f1;
  //margin-left: -15px !important;
  outline: none !important;
  border: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../../icons/input-select-arrow-right.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 5px;
  color: $dark;
  cursor: pointer;

  &:focus {
    background-color: #f0f0f1;
  }
}

.line-select {
  //padding-left: 0;
  font-size: 1.35rem;
  font-weight: 600;
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../../icons/input-select-arrow-right.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 5px;
  color: $black;
  cursor: pointer;
}

.select-no-line {
  font-size: 1.35rem;
  font-weight: 600;
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../../icons/input-select-arrow-right.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 5px;
  color: $black;
  cursor: pointer;
  background-color: transparent;
  border: none !important;
  padding-left: 0;

  &:focus {
    background-color:  transparent;
  }
}

.input-textarea {
  background-color: #f0f0f1;
  resize: none;
  outline: none !important;
  border: none !important;
  padding-left: 0;
  font-size: 1.35rem;
  font-weight: bold;
  margin-top: 5px;

  &:focus {
    background-color: #f0f0f1;
  }
}