.contact-info {
  padding-bottom: 50px;
  height: 100%;
  background-color: $white;
  min-height: 480px;

  .info-item {
    text-align: center !important;
    margin: 40px 0;

    .icon-container {
      background-color: $primary;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      padding-top: 2px;
      margin: 0 auto;
    }

    .icon {
      height: auto;
      fill: $white;
      padding: 5px;

      &.telephone {
        width: 36px;
      }

      &.form {
        width: 34px;
        margin-left: 3px;
      }

      &.location {
        width: 28px;
      }
    }

    .text {
      margin-top: 15px;
      font-weight: 600;
    }
  }
}

.contact-footer {


  .social-networks-contact {
    height: 110px;
    display: inline-flex;
    width: 100%;
    justify-content: center;
    background-color: $primary;
    position: absolute;
    bottom: 0;

    .social-container {
      width: 30%;
      margin-top: 20px;

      .social, a {
        color: rgba(255, 255, 255, 0.8);
        opacity: 0.8;
        text-align: center;
        display: block;

        .icon-social {
          width: 36px;
          height: 36px;
          fill: $white;
          margin-top: 5px;
          cursor: pointer;
        }

        .social-name {
          font-weight: 600;
          font-size: 1.15rem;
          margin-top: 5px;
        }
      }

      .social:hover, a:hover {
        opacity: 1;
        color: $white;
      }
    }
  }
}

.suggestion-container {
  background-color: $white;
  min-height: 100vh;
  height: 100%;

  .form-suggestion {
    padding: 60px 45px 25px 45px;

    .suggestion-container {
      min-height: 55vh;
      background-color: #F0F0F1;
    }

    .suggestion-textarea {
      min-height: 53vh;
    }

    .suggestion-footer {
      margin-top: 90px;
    }
  }
}


