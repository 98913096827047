@import 'res/css/bootstrap/bootstrap-grid';
@import 'res/css/bootstrap/bootstrap-reboot';
@import 'res/css/bootstrap/bootstrap';

@import 'res/css/480/custom';
// General
@import 'res/css/480/general';
@import 'res/css/480/color-utilities';
@import 'res/css/480/card';
@import 'res/css/480/font-sizes';
@import 'res/css/480/form-signin';
@import 'res/css/480/forms';
@import 'res/css/480/helpers';
@import 'res/css/480/mixins';

@import 'res/css/480/navbar';
@import 'res/css/480/switch';

@import 'res/css/480/start-screen';
@import 'res/css/480/terms';
@import 'res/css/480/checkbox';
@import 'res/css/480/modal';
@import 'res/css/480/dropdown';
@import 'res/css/480/park';
@import 'res/css/480/ticket';
@import 'res/css/480/payment-card';
@import 'res/css/480/park-summary';
@import 'res/css/480/unpark';
@import 'res/css/480/complaint';
@import 'res/css/480/pager';
@import 'res/css/480/personal-area';
@import 'res/css/480/contact';
@import 'res/css/480/configuration';
@import 'res/css/480/history';