
.row-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;

  width: 24px;
  height: 24px;
}

.row-checkbox .row-input-checkbox {
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 3px;
  border: 3px solid $secondary;
  cursor: pointer;

  margin-top: -3px;
}

.row-checkbox input:checked ~ .row-input-checkbox {
  background-color: $primary;
  border-radius: 3px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 3px solid $primary;

  margin-top: -3px;
}

input:disabled:checked ~ .row-input-checkbox {
  background-color: $secondary;
  border: 3px solid $secondary;

  margin-top: -3px;
}

.row-checkbox .row-input-checkbox::after {
  position: absolute;
  content: "";
  left: 0;
  top: 6px;
  height: 0;
  width: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  opacity: 1;

  margin-top: -3px;
}

.row-checkbox input:checked ~ .row-input-checkbox::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 4px;
  top: 0;
  width: 10px;
  height: 100%;
  border: solid $light;
  border-width: 0 4px 4px 0;
  background-color: transparent;
  border-radius: 0;

  margin-top: -2px;
}

.label-checkbox {
  margin-top: -5px !important;
  padding-left: 45px;
  font-weight: 400;
  font-size: 1.35rem;
}