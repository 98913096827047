
// BS overrides

$primary:       #67A1D3;
$secondary:     #8C8F93;
$accent:     	#1CB1FF;
$success:       #73B43B;
$info:          #2A4841;
$warning:       #ffc600;
$danger:        #ff4e1b;
$dark:          #4A4A4A;
$light:         #E6E6E6;
$blue-light:    #F6F6F6;
$white:         #FFFFFF;
$black:			#000000;
$navbar:        #2C2C2C;
$blue-middle:   #356187;
$blue-dark:     #294054;

$theme-colors: ();
$theme-colors: map-merge((
"primary":    $primary,
"secondary":  $secondary,
"accent":  		$accent,
"success":    $success,
"info":       $info,
"warning":    $warning,
"danger":     $danger,
"light":      $light,
"navbar":      $navbar,
"white":      $white,
"dark":       $dark
), $theme-colors);

$body-color:                $dark;

$link-color:                $dark;
$link-decoration:           none;
$link-hover-color:          $black;
$link-hover-decoration:     none;

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-monospace:  SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$headings-font-family:   -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$headings-font-weight:        200;
$headings-line-height:        1.05;

$nav-link-padding-y:1.35rem;

$grid-breakpoints: (
xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1400px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
// sm: 540px,
// md: 720px,
// lg: 960px,
sm: 758px,
md: 962px,
lg: 1242px,
xl: 1242px
);

@mixin resized-width {
  width: 100vw;
  max-width: 1024px;
  //@media only screen and (max-width: 768px) {
  //  width: 100%;
  //}
  //@media only screen and (min-width: 769px) {
  //  width: 50%;
  //}
}
