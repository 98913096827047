.history-container {
  color: $white !important;
  text-align: center;
  min-height: 100vh;
  z-index: 0;

  .section-container {
    height: 65px;
    width: 100%;
    display: inline-flex;
    background-color: $white;
    color: $black;

    .section {
      height: 100%;
      width: 33.99%;
      padding-top: 20px;
      border-bottom: 3px solid $gray-400;
      cursor: pointer;

      &.active {
        border-bottom: 4px solid $success;
      }
    }
  }
}

.billing-container {
  background-color: $white;
  min-height: 100vh;
  height: 99%;

  .empty-text {
    text-align: center;
    color: $black;
    padding-top: 50px;
  }

  .list-billing {
    text-align: left;
    padding: 25px;

    .title {
      color: $blue-middle;
      font-weight: 700;
    }

    .billing {
      display: inline-flex;
      width: 100%;
      height: 90px;
      padding-top: 20px;
      padding-left: 15px;
      padding-right: 15px;
      border-bottom: 1px solid lighten($secondary, 35);

      .data-container {
        width: 75%;

        .date {
          color: $secondary;
          margin-bottom: 5px;
        }

        .site {
          color: $blue-middle;
          font-weight: 700;
        }
      }

      .icon-container {
        width: 25%;
        margin-top: 10px;
        text-align: right;
        //margin-right: 25px;

        .icon {
          background-color: #ff82e9;
          width: 100%;
        }
      }

      .icon-download {
        width: 25px;
        height: auto;
        fill: $primary;

        //position: absolute;
        //right: 0;
        //margin-right: 10px;
        //align-self: center;
      }
    }
  }
}

.transaction-container {
  background-color: $white;
  min-height: 100vh;
  height: 99%;

  .empty-text {
    text-align: center;
    color: $black;
    padding-top: 50px;
  }

  .list-container {
    text-align: left;
    padding: 25px;

    .transaction-list {
      //margin-right: -38px;
      //margin-left: -37px;
      //padding-right: 38px;
      //padding-left: 37px;
      border-bottom: 1px solid lighten($secondary, 30);
      color: $secondary;
      margin-bottom: 25px;
      padding-bottom: 25px;

      .transaction {
        div, span {
          font-size: 1.25rem !important;
        }

        display: flex;

        .icon-time {
          width: 20px;
          height: auto;
          fill: $secondary;
          align-self: start;
          margin-right: 15px;
          margin-top: -3px;
        }

        .icon-arrow-right {
          width: 25px;
          height: auto;
          fill: $primary;
          position: absolute;
          right: 0;
          margin-right: 25px;
          align-self: center;
        }


        .data {
          .time-data {
            padding-bottom: 5px;
          }

          .date-licplate {
            padding-bottom: 5px;

            .date {
              color: $blue-middle;
              font-weight: 600;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }


}

.transaction-detail-container {
  .message-info {
    display: inline-flex;

    .icon-warning {
      width: 32px;
      height: auto;
      fill: $white;
      margin-right: 15px;
    }
  }

  .boxes-info {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    margin-top: 25px;

    .box {
      background-color: lighten($dark, 10);
      width: 50%;
      border-radius: 10px;
      //height: 140px;
      padding: 15px;
      display: inline-grid;

      .text {
        font-size: 1.05rem;
        font-weight: 600;
      }

      .data {
        font-size: 2rem;
        color: $primary;
        margin-top: 15px;
        text-align: right;
        font-weight: 600;
        align-self: flex-end;
      }
    }
  }

  .info-section {
    margin-top: 35px;
    text-align: center;

    .section {
      .icon {
        fill: $primary;
        width: 24px;
        height: auto;
      }

      .text {
        margin-top: 5px;
        font-weight: 600;
      }
    }
  }

  &.light {
    .boxes-info {
      .box {
        background-color: $blue-middle;

        .text {
          color: $secondary;
        }

        .data-time {
          color: $white;
          font-weight: 600;
        }

        .data {
          color: $white;
          margin-top: 55px;

          &.emission {
            margin-top: -5px;
            text-align: center;
          }
        }

        .commission {
          color: $white;
          font-size: 0.88rem;
          text-align: right;
          font-weight: 600;
        }
      }
    }
  }

  &.dark {
    //background-color: $dark;

    .boxes-info {
      .box {
        background-color: lighten($dark, 15);

        .text {
          color: $white;
        }

        .data-time {
          color: $primary;
          font-weight: 600;
        }

        .data {
          &.emission {
            margin-top: -5px;
            text-align: center;
          }
        }

        .commission {
          color: $primary;
          font-size: 0.90rem;
          text-align: center;
          font-weight: 600;
        }
      }
    }

    .info-section {
      .text {
        color: $white;
      }
    }
  }

  .bar {
    margin-top: 50px;
    margin-left: 60px;
    margin-right: 60px;
    border: 1px solid $secondary;
  }

  .show-pdf {
    margin-top: 25px;
    text-align: center;
    color: $success;
    font-weight: 600;
  }

  &.footer-img-car {
    position: relative;
    margin-top: 250px;

    .img-content {
      position: absolute;
      bottom: -5px;
      margin: 0;
      left: -5px;
      right: -5px;
      clip-path: inset(1%);

      svg {
        width: 120%;
        height: auto;
      }
    }
  }
}