.park-summary-container {
  min-height: 100vh;

  .new-park {
    height: 75px;
    line-height: 75px;
    background-color: $dark;
    text-align: center;
    position: relative;
    cursor: pointer;
    color: $primary;
    font-weight: 600;

    .icon-add {
      fill: $primary;
      height: 28px;
      width: auto;
      margin-right: 15px;
      margin-top: -5px;
    }
  }

  .summary-card {
    margin: 25px 35px 25px 25px;
    background-color: $white;
    min-height: 75vh;
    border-radius: 10px;
    text-align: center;
    padding: 25px;
    color: $secondary;

    .total-time {
      color: $primary;
      font-size: 2.3rem;
      font-weight: 700;
    }

    .card-section {
      margin-top: 25px;

      .icon {
        height: 32px;
        width: auto;
        fill: $primary;
      }

      .where {
        color: $primary;
        margin-top: 5px;
        font-weight: 700;
      }
    }

    .price {
      color: $primary;
      font-size: 1.8rem;
      font-weight: 700;
    }
  }

  .summary-font-size {
    font-size: 1.1rem;
  }
}