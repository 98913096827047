//Margin
@for $i from -25 through 25 {
  .m-l-#{$i * 5} {
    margin-left: #{$i * 5}px !important;
  }

  .m-t-#{$i * 5} {
    margin-top: #{$i * 5}px !important;;
  }

  .m-r-#{$i * 5} {
    margin-right: #{$i * 5}px !important;;
  }

  .m-b-#{$i * 5} {
    margin-bottom: #{$i * 5}px !important;;
  }

  .m-#{$i * 5} {
    margin: #{$i * 5}px !important;;
  }
}

.margin-0 {
  margin: 0 !important;;
}

.m-t--5 {
  margin-top: -5px !important;
}

@for $i from 0 through 25 {
  .p-l-#{$i * 5} {
    padding-left: #{$i * 5}px !important;;
  }

  .p-t-#{$i * 5} {
    padding-top: #{$i * 5}px !important;;
  }

  .p-r-#{$i * 5} {
    padding-right: #{$i * 5}px !important;;
  }

  .p-b-#{$i * 5} {
    padding-bottom: #{$i * 5}px !important;;
  }
}

.padding-0 {
  padding: 0 !important;;
}

@for $i from 5 through 49 {
  .font-#{$i + 1} {
    font-size: #{$i + 1}px !important;;
  }
}

.align-left {
  text-align: left !important;
}

.align-center {
  text-align: center !important;
}

.align-right {
  text-align: right;
}

.align-justify {
  text-align: justify;
}

.no-resize {
  resize: none;
}


.font-bold {
  font-weight: 700;
}

.font-middle-bold {
  font-weight: 600;
}

.font-italic {
  font-style: italic;
}

.font-underline {
  text-decoration: underline;
}

.font-line-through {
  text-decoration: line-through;
}

.font-overline {
  text-decoration: overline;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.display-inline-flex {
  display: inline-flex;
}

.visibility-hidden {
  visibility: hidden;
}

.background-transparent {
  background: transparent !important;
}

.text-inline {
  clear: both;
  display: inline-block;
  //overflow: hidden;
  white-space: nowrap;
}

.pointer {
  cursor: pointer;
}

.text-line-through {
  text-decoration: line-through;
}

.disable-cursor {
  cursor: auto !important;
}
