.card {
  @include resized-width;

  //background: $light;
  background: transparent;
  //margin-bottom: 30px;
  //min-height: 50px;
  min-height: 100vh;
  position: relative;
  border: none !important;

  .card-inside-title {
    color: #000;
    display: block;
    font-size: 15px;
    margin-bottom: 15px;
    margin-top: 25px;

    small {
      color: #999;
      display: block;
      font-size: 11px;
      margin-top: 5px;

      a {
        color: #777;
        font-weight: bold;
      }
    }
  }

  .card-inside-title:first-child {
    margin-top: 0;
  }

  .bg-red,
  .bg-pink,
  .bg-purple,
  .bg-deep-purple,
  .bg-indigo,
  .bg-blue,
  .bg-light-blue,
  .bg-cyan,
  .bg-teal,
  .bg-green,
  .bg-light-green,
  .bg-lime,
  .bg-yellow,
  .bg-amber,
  .bg-orange,
  .bg-deep-orange,
  .bg-brown,
  .bg-grey,
  .bg-blue-grey,
  .bg-black {
    border-bottom: none !important;
    color: #fff !important;

    h2, small, .material-icons {
      color: #fff !important;
    }

    .badge {
      background-color: #fff;
      color: #555;
    }
  }

  .header {
    //color: #555;
    padding: 20px;
    position: relative;


    .header-dropdown {
      list-style: none;
      position: absolute;
      right: 15px;
      top: 20px;

      .dropdown-menu {
        li {
          display: block !important;
        }
      }

      li {
        display: inline-block;
      }

      i {
        color: #999;
        font-size: 20px;
        @include transition(all .5s);

        &:hover {
          color: #000;
        }
      }
    }

    h2 {
      font-size: 1.5rem;
      margin: 0;

      small {
        color: $secondary;
        display: block;
        font-size: 12px;
        line-height: 15px;
        margin-top: 5px;

        a {
          color: $primary;
          font-weight: bold;
        }
      }
    }

    .col-xs-12 {
      h2 {
        margin-top: 5px;
      }
    }
  }

  .body {
    color: #555;
    font-size: 14px;

    @for $i from 1 through 12 {
      .col-xs-#{$i},
      .col-sm-#{$i},
      .col-md-#{$i},
      .col-lg-#{$i} {
        margin-bottom: 20px;
      }
    }
  }

  .resolved-alert {
    -webkit-transition-duration: .12s;
    background-color: transparent;
    border-bottom: 1px solid rgba($secondary, .25);
    padding: 2rem 1rem;
    transition-duration: .12s;

    &:hover {
      background-color: rgba($primary, .15);
    }
  }

  .unresolved-alert {
    -webkit-transition-duration: .12s;
    background-color: rgba($primary, .1);
    border-bottom: 1px solid rgba($secondary, .25);
    padding: 2rem 1rem;
    transition-duration: .12s;

    &:hover {
      background-color: rgba($primary, .15);
    }
  }

  .title-alert {
    color: $dark;
  }

  .time-alert {
    // font-weight: bolder;
    color: rgba($dark, .8);
    font-size: 1.1rem;
    letter-spacing: .05rem;
    margin-bottom: 2rem;

    i {
      font-size: 1.2rem;
      margin-right: 1rem;
    }
  }

}

.card-search-filter {
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;

  .input-group {
    margin-bottom: 0;
    margin-right: 5px;
  }

  .header-dropdown {
    top: 9px !important;
  }

  .btn-group {
    -webkit-box-shadow: none;
    box-shadow: none;
    vertical-align: initial;
  }

  .header-item {
    margin-left: 10px;

    i {
      color: $dark;
      font-size: 20px;
      margin-top: 8px;
    }
  }

  a {
    -webkit-transition-duration: .2s;
    cursor: pointer;
    transition-duration: .2s;

    &:hover {
      -webkit-transition-duration: .2s;
      transition-duration: .2s;

      i {
        color: $primary;
      }
    }
  }

}

.display-none {
  display: none;
}

.card-collapsed-error {
  border-style: solid;
  border-width: 2px;
  border-color: $danger !important;
}

.card-list {
  background-color: transparent !important;
}

.card-custom-fields {
  background-color: $white !important;
  padding: 10px;
  border: 15px solid $light !important;
}

.card-invoice-props {
  margin: 1px 0 0 0;
  padding: 0 1px 0 1px;
  background-color: $white;
  min-height: 85vh !important;
  max-height: 85vh !important;

  .btn-tab-props {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    text-align: center;
    border-bottom: none;
  }

  .input-receive-props {
    &:disabled {
      color: $dark;
    }

  }
}

.card-container {
  margin-left: 15px;
  margin-right: 15px;
}

.company-card {
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;

  .company-container {
    position: relative;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .company-data {
    float: left;

    .card-subtitle {
      color: $secondary;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .container-check {
    position: absolute;
    float: right;
    right: 0;
    height: 100%;
    top: 30%;
    margin-right: 25px;

    .icon-check {
      font-size: 2.2rem;
    }
  }

  &:hover {
    background-color: lighten($light, 6%) !important;
  }
}

.validate-container {
  width: 100%;
  margin: 15px 15px 30px 30px;

  .validate-header {
    height: 60px;
  }

  .validate-btn {
    float: right;
  }

  .validate-card {
    background-color: $light;
    width: 100%;
    border-radius: 20px;
    margin-top: 0;
    padding-left: 15px;
    padding-right: 15px;

    flex-direction: row;

    .drag-indicator {
      font-size: 0.9rem;
      cursor: pointer;
      float: left;
      margin-top: 28px;
    }

    .validate-card-content {
      margin-left: 20px;
      margin-top: 15px;
      width: 100%;
      padding-bottom: 15px;

      .btn-add {
        font-size: 0.85rem;
        text-align: center;
        background-color: $primary;
        color: $white;
        padding: 2px 15px 2px 15px;
        float: right;
      }

      .users-container {
        margin-top: -8px;

        .user-tag {
          background-color: $white;
          color: $secondary;
          width: fit-content;
          padding: 2px 15px 2px 15px;
          font-size: 0.85rem;
          border-radius: 20px;
          display: inline-table;

          .icon-close {
            margin-left: 15px;
            color: $primary;
            font-size: 0.8rem;
            cursor: pointer;
          }
        }

      }
    }
  }

  .icon-group-close {
    font-style: normal;
    position: absolute;
    z-index: 999;
    right: 0;
    margin-right: 20px;
    margin-top: 7px;
    cursor: pointer;
  }
}

.background-light {
  background-color: $light;
}

.show-scrollbar-y {
  overflow-y: auto;
}

.hidden-scrollbar-y {
  overflow-y: hidden;
}

.transparent-background {
  background-color: transparent;
}