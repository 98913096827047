.configuration-container {
  background-color: $white;
  min-height: 100vh;
  height: 100%;
  padding: 60px 45px 25px 45px;

  .title, label {
    font-size: 1.35rem;
    font-weight: 600;
    color: $black;

  }

  .switch-wrapper {
    display: inline-flex;
    background-color: transparent;
    padding: 0;

    .icon-pin {
      width: 38px;
      height: auto;
      fill: $primary;
      margin-left: 35px;
      margin-right: 20px;
    }

    .form-switch {
      margin-top: -2px;
      width: 100%;
      text-align: right;
      margin-right: -20px;
    }
  }

  .checkbox-label {
    margin-left: 15px;
    margin-top: -3px;
  }

  .remember-pin {
    color: $primary;
    margin-top: 20px;
    margin-left: 80px;
  }
}