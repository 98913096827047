.list-card-container {
  width: 90%;
  display: table;
  margin: 0 auto;
  text-align: center;

  .card-container {
    width: 100%;
    //max-width: 500px;
    background-color: lighten($secondary, 37);
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    display: inline-flex;
    margin-left: 0;
    margin-bottom: 25px;

    .radio {
      margin-left: 30px;
      margin-top: 6px;
    }

    .number-card {
      margin-left: 30px;
      letter-spacing: 0.28rem;
      margin-top: 5px;
      margin-right: 10px;
      font-weight: 600;
    }

    .menu {
      width: 100%;

      .dropdown {
        text-align: end;
        margin-right: 20px;
      }
    }
  }
}

.add-card {
  .modal-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.section-top {
  margin-top: 60px;
}

.content-form-footer {
  &.section {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 60px;
    padding-bottom: 25px;
  }
}

.col-date {
  padding-left: 0;
  padding-right: 0;

  @media (min-width: 750px) {
    padding-right: 15px;
  }
}

.col-cvv {
  padding-left: 0;
  padding-right: 0;
  margin-top: 20px;

  @media (min-width: 750px) {
    margin-top: 0;
    padding-left: 15px;
  }

}

.card-small-inputs {
  display: inline-flex;
}

.card-inputs {
  display: inline-flex;

  .input-date {
    padding: 0 !important;

    .input-date-error {
      //position: absolute;
      position: absolute;
      z-index: 99999;
      //background-color: blue !important;
    }
  }

  .date-separator {
    font-weight: 600;
    margin-top: 8px;
  }
}
