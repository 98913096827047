.unpark-container {

  .unpark-header {
    background-color: lighten($secondary, 38);
    margin-top: -1px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 80px;
  }

  .unpark-section {
    text-align: center;
    margin-top: 15px;
    font-weight: 600;
    font-size: 1.15rem;

    .info {
      margin-bottom: 20px;
      font-weight: 700;
      color: $primary;
      font-size: 1.8rem;
    }
  }
}
