.form-control {
  border: none;
  border-bottom: 1px solid $light;
  border-radius: 0;
}

.form-group {
  margin-bottom: 0 !important;

  .form-line-icon {
    position: relative;

    i {
      position: absolute;
      top: 35px;
      right: 10px;
      font-size: 20px;
    }
  }
}

.form-group, label, small {
  background-color: transparent;
}

.form-control {
  &:focus {
    border: none;
    border-bottom: 2px solid $success;
    box-shadow: none;
  }
}

label {
  font-size: .85rem;
}

.form-company-detail {
  width: 1200px;
}

.card-body {
  background-color: $white;
}

.form-inputs-card {
  background-color: $white;
  border: 15px solid $light;
  padding: 10px;
}

.form-invoice-master {
  padding: 10px;
}

.form-label-md {
  width: 85px;
}

.form-label-sm {
  width: 45px;
}

.icon-add-tax {
  cursor: pointer;
  font-size: 1.15rem;
}

.btn-form {
  background-color: $secondary;
  color: $white;
  //padding-left: 10px;
  //padding-right: 10px;
  font-size: 1rem;
  text-align: center;
  white-space: nowrap;
}

.btn-form-primary {
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid $black;
  font-size: 0.80rem;
  text-align: center;
  white-space: nowrap;

  &:hover {
    background-color: $black;
    color: $light;
  }
}

.btn-cancel {
  &:hover {
    color: $white;
    background-color: $primary !important;
    border: 1px solid transparent;
  }
}

.form-recover-password {
  text-align: center;
  padding-bottom: 160px;
}

.form-footer-container {
  margin-left: 45px;
  margin-right: 45px;
  padding: 40px 45px 20px 45px;
  bottom: 0;

  &.confirm-btns {
    padding: 0;
    margin: 0;
    display: inline-flex;
    width: 100%;

    .btn-cancel, .btn-submit {
      width: 50%;
    }
  }
}

.permission-selector-container {
  background-color: $light;
  border-radius: 20px;
  padding: 20px 20px 10px 20px;
}

.permissions-selector {
  position: relative;
  display: inline-flex;
  width: 100%;
  margin-left: 0;
}

.permissions-selector-icon {
  position: absolute;
  font-size: 1.2rem;
  cursor: pointer;
  right: 0;
  margin-right: 0;
  margin-top: 0.25rem;
}

.min-card-container {
  background: transparent !important;
}

.min-card-body {
  border-radius: 20px;
  background-color: $white;
  margin-right: 5px !important;
  margin-bottom: 0 !important;
  padding: 15px 15px 0 15px !important;
}

input:disabled + .row-input-checkbox {
  border-color: lighten($secondary, 25%);
}

label.error {
  color: $danger !important;
  font-size: 1.2rem;
  font-weight: normal;
  margin-top: 2px;
  font-style: italic;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.form-line.error {
  &:after {
    border-bottom: 2px solid $danger;
  }
}

.form-line.success {
  &:after {
    border-bottom: 2px solid $success;
  }
}

.form-line.warning {
  &:after {
    border-bottom: 2px solid $warning;
  }
}

.checkbox-group {
  display: inline-flex;
  align-items: center;
  flex-direction: row !important;
}

.input-tax-value {
  margin-top: -15px !important;
}

.input-tax-type {
  margin-top: 5px !important;
}

.form-control:disabled {
  background-color: transparent;
  color: lighten($secondary, 8%);
}

.input-filter {
  &:focus {
    border-bottom: 2px solid $input-border-color;
  }
}

.input-filter {
  .form-control {
    &:after {
      border-bottom: 2px solid $input-border-color;
    }
  }
}

.menu-options {
  float: right;
  margin: -4px -15px -15px 10px;
  border-left: 1px solid white;
}

.form-footer-invoice {
  background-color: $white;
  margin-top: 1px;
  margin-left: -14px;
  margin-right: -15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 25px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  &.footer-edit {
    padding-bottom: 30px;
    //position: relative;
  }

}

.form-inline-icon {
  position: relative;
  display: inline-flex;
  background-color: $white;

  i {
    margin-top: 10px;
    font-size: 14px;
    color: $dark;
    margin-left: -10px;
    margin-right: 10px;
    cursor: pointer;
  }
}

.responsible-title {
  margin-top: 35px;
  margin-left: 15px;
  font-weight: bold;
}

.responsible-title-field {
  margin-top: 15px;
  margin-left: 0;
  font-size: .85rem;
  color: $secondary;
}

.responsible-field {
  margin-bottom: -60px;
  padding-bottom: 0;

  .permission-checkbox {
    margin-top: 10px;
  }
}

.row-icon-add {
  margin-top: 5px;
  padding-left: 0;

  .icon-add-field {
    cursor: pointer;
    font-size: 1.15rem;
  }
}

.check-duplicate-error {
  margin-top: -15px !important;
  margin-left: 15px !important;
}

.card-text {
  font-weight: 400;
  font-size: 1rem;
}

.required-field {
  font-weight: bold;
  color: $danger;
  font-size: 0.90rem;
}


.disabled-legacy-users {
  input {
    margin-top: 3px;
    border: none !important;
  }
}

.add-responsible {
  color: $primary !important;
  font-size: 1.15rem;
  margin-left: -6px;
}

.active-label {
  font-size: 0.85rem;
  padding: 3px;
}

.form-wrapper {
  background-color: lighten($secondary, 38);
  display: inline-block;
  width: 100%;
  border-radius: 5px 5px;
  padding: 15px 20px;

  label {
    display: block;
    font-size: 1.2rem;
    color: $secondary;
    margin-bottom: -5px;
  }

  input, input:focus {
    padding-left: 0;
    font-size: 1.35rem;
    font-weight: bold;
    background-color: lighten($secondary, 38);
    margin: 0;
    outline: none !important;
    border: none;
  }
}

.input-icon {
  display: inline-flex;
  width: 100%;

  .icon {
    width: 32px;
    height: auto;
    position: absolute;
    fill: $primary;
  }

  input {
    padding-left: 45px;
  }
}

.label-line {
  padding-left: 5px;
}

.input-line, .input-line:focus {
  font-size: 1.35rem !important;
  font-weight: 600;
  color: $black;
}

.select-city {

  .icon-city {
    width: 26px;
    height: auto;
    fill: $dark !important;
  }

  .icon-services {
    fill: lighten($secondary, 15);

    &.icon-parking-meter {
      width: 18px;
      height: auto;
    }

    &.icon-parking {
      width: 22px;
      height: auto;
      margin-top: 2px;
    }
  }

  .current-city {
    color: $primary;
  }

  .icon-check {
    fill: $primary;
    width: 24px;
    height: auto;
    margin-top: -5px;
  }
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 9px;
  cursor: pointer;
  animate: 0.2s;
  background: $primary;
  border-radius: 25px;
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px $secondary;
  border: 1px solid $secondary;
  height: 32px;
  width: 32px;
  border-radius: 25px;
  background: #FFFFFF;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -11px;
}
