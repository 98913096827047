.start-container {
  color: $white !important;
  text-align: center;
  padding: 0 !important;
  margin: 0 !important;
  min-height: 100vh;
  height: 100%;
  background-color: transparent !important;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    height: 280px;

    .vertical-logo {
      width: 175px;
      height: auto;
    }
  }

  .slogan {
    padding: 40px 45px 25px 45px;
    height: 300px;

    .title {
      margin: 0;
      font-size: 2rem;
      font-weight: 700;
    }

    .subtitle {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 600;
      @media only screen and (max-width: 576px) {
        margin-top: 15px;
      }
    }
  }

  .platforms {
    height: 135px;

    .icon-platform {
      width: 36px;
      height: 36px;
      fill: $white;
      cursor: pointer;
      margin-left: 5px;
      margin-top: -15px;
      opacity: 0.6;

      &.icon-google-play {
        margin-right: 10px;
        margin-top: 0.05rem;
      }
    }
    .icon-platform:hover {
      opacity: 1;
    }
  }

  .social-networks {
    height: 110px;
    display: inline-flex;
    width: 100%;
    justify-content: center;

    .social-container {
      width: 30%;

      .social, a {
        color: rgba(255, 255, 255, 0.5);
        opacity: 0.6;

        .icon-social {
          width: 36px;
          height: 36px;
          fill: $white;
          margin-top: 5px;
          cursor: pointer;
        }

        .social-name {
          font-weight: 600;
          font-size: 1.15rem;
          margin-top: 5px;
        }
      }

      .social:hover, a:hover {
        opacity: 1;
        color: $white;
      }
    }
  }

  .footer {
    height: 80px;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;

    .btn-start {
      border-radius: 0 !important;
      width: 50%;
      height: 100%;
      color: $white;

      &.btn-login {
        background-color: $dark;
        float: left;
      }

      &.btn-register {
        background-color: $primary;
        float: right;
      }

      @media only screen and (max-width: 375px) {
        width: 100%;
      }
    }
  }
}

.start-background {
  background-image: linear-gradient(to bottom, rgba(122, 106, 106, 0.45), rgba(55, 46, 46, 0.75)), url("../../img/bg-image.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}