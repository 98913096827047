.signin-container {
  background-color: $white;
  min-height: 100vh;
  height: 100%;

  .head {
    background-color: $blue-light;
    height: 300px;
    color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .head-img {
      margin-bottom: 25px;
    }

    .title {
      font-size: 1.15rem;
      font-weight: 600;
    }
  }
}

.form-signin {
  padding: 40px 45px 25px 45px;

  .icon-phone {
    height: 30px !important;
    width: auto !important;
    margin-top: 1px;
  }

  .icon-dni {
    height: 21px !important;
    width: auto !important;
    margin-top: 7px;
  }

  .icon-mail {
    height: 30px !important;
    width: auto !important;
    margin-top: 7px;
  }

  .icon-password {
    height: 30px !important;
    width: auto !important;
    margin-top: 7px;
  }

}

.signin-title {
  background-color: transparent;
  font-family: 'Montserrat';
  font-size: 1.25rem;
  font-weight: bold;
  padding-top: 2.3rem;
  text-align: center;
  color: $secondary;
}

.signin-subtitle {
  background-color: transparent;
  font-family: 'Montserrat';
  font-size: 1.10rem;
  font-weight: normal;
  padding-bottom: 2.3rem;
  text-align: center;
}

.footer-create-account {
  height: 100px;
  text-align: center;
  bottom: 0;
}

.form-add-company, .form-create-account {
  max-width: 52rem !important;
}

.card-info {
  max-width: 40rem !important;
}

.btn-center {
  margin: 0 auto;
}

.btn-account {
  margin-top: 50px !important;
}

.forget-password-link {
  color: $secondary !important;
}

.error-message {
  color: $danger;
  font-weight: 400;
  font-style: italic;
}

.info-message {
  color: lighten($secondary, 15);
  font-size: 1rem;
  font-weight: 600;

  .icon-info {
    width: 14px;
    height: auto;
    fill: lighten($secondary, 15);
  }
}

.register-success-container {
  width: 100%;
  padding: 10vh 30px;

  .success-message {
    text-align: center;
    font-weight: 600;
  }

  .app-icons {
    margin-top: 6vh;
    text-align: center;
    img {
      margin: 15px;
    }
  }

  .login-message {
    margin-top: 6vh;
    text-align: center;
    font-weight: 600;
  }
}