.park-container {
  color: $white !important;
  text-align: center;
  min-height: 100vh;
  z-index: 0;

  .car-section {
    height: 75px;
    background-color: $black;
    text-align: center;
    padding-top: 5px;
    position: relative;
    cursor: pointer;

    .icon-car {
      width: 36px;
      height: auto;
      fill: $white;
    }

    .car-data {
      padding-top: 5px;
    }

    .icon-arrow {
      width: 28px;
      height: auto;
      fill: $white !important;
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 25px;
      margin-top: 20px;
    }
  }

  .situation-info {
    position: fixed;
    width: 30vw;
    min-width: 200px;
    height: 4.5vh;
    background-color: $secondary;
    bottom: 0;
    margin-bottom: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translate(-50%, 0);

    &.inside {
      background-color: $success;
      cursor: pointer;
    }
  }
}

.list-vehicle {
  width: 90%;
  display: table;
  margin: 0 auto;
  text-align: center;

  .vehicle-container {
    width: 100%;
    //max-width: 500px;
    background-color: lighten($secondary, 37);
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    display: inline-flex;
    margin-left: 0;
    margin-bottom: 25px;

    .radio {
      margin-left: 30px;
      margin-top: 6px;
    }

    .car-data {
      margin-left: 25px;
      margin-top: 5px;

      .licplate {
        margin-top: 15px;
        margin-right: 10px;
        font-weight: 600;
      }

      .description {
        color: $secondary;
        white-space: nowrap;
        margin-left: 10px;
      }
    }

    .menu {
      width: 100%;

      .dropdown {
        text-align: end;
        margin-right: 20px;
      }
    }
  }
}

.content-form-footer {
  border-top: none !important;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.75rem;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.actions-section {
  height: 86px;
  background-color: $primary;
  color: $white;
  display: inline-flex;
  width: 100%;

  .action {
    height: 100%;
    padding-top: 10px;

    &.site {
      //width: 33%;
      width: 50%;
    }

    &.ticket {
      //width: 33%;
      width: 50%;
    }

    &.cancellation-complaint {
      width: 34%;
      line-height: 1;
    }

    &.active {
      background-color: darken($primary, 15) !important;
      cursor: auto !important;
    }

    &:hover {
      cursor: pointer;
      background-color: darken($primary, 15) !important;
    }

    .icon {
      fill: $white;
      width: 22px;
      height: auto;

      &.icon-ticket, &.icon-complaint {
        width: 36px;
      }
    }
  }
}

.list-sector {
  width: 90%;
  display: table;
  margin: 0 auto;
  text-align: center;

  .sector-container {
    width: 100%;
    //max-width: 500px;
    background-color: lighten($secondary, 37);
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    display: inline-flex;
    margin-left: 0;
    margin-bottom: 25px;

    .radio {
      margin-left: 30px;
      margin-top: 6px;
    }

    .sector-data {
      margin-left: 25px;
      margin-top: 5px;
    }
  }
}

.vehicle-form-optional {
  margin-top: 5px;
  padding-right: 15px;
  margin-left: 5px;
  width: 100%;

  .optional-title {
    width: 100%;
    display: inline-flex;

    .title {
      font-size: 1.5rem;
      font-weight: 600;
      width: 100%;
    }

    .optional-input {
      text-align: end;
      margin-right: 5px;
      margin-top: -11px;
    }

    .optional-icon {
      text-align: right;
      .icon-right {
        width: 36px;
        height: auto;
        margin-right: -10px;
        fill: $dark;
        margin-top: -11px;
      }
    }
  }

  .optional-description {
    color: $secondary;
  }

  .optional-info {
    color: $primary;
    font-weight: 700;
    cursor: pointer;
  }
}