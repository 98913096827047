$navbar-height: 5.40rem;
$inside-navbar: 5.15rem;
$arrow-item-color: lighten($navbar, 4);

.navbar {
  background-color: $white;
  height: $navbar-height;

  &.navbar-nav {
    padding-top: 1.3rem;
    padding-right: 0;

    .menu-container {
      display: inline-flex;
      width: 100%;

      .icon-container {
        position: absolute;
        margin-left: 25px;

        .icon-menu {
          fill: $primary;
          width: 32px;
          height: 32px;
          cursor: pointer;
        }
      }

      .title-container {
        width: 100%;
        text-align: center;
        margin-top: 5px;
      }
    }

    //
    //.container-notification {
    //  width: 18%;
    //  text-align: right;
    //  margin-right: 5px;
    //
    //  .icon-notification {
    //    position: relative;
    //    cursor: pointer;
    //
    //    .icon-bell {
    //      fill: $primary;
    //      width: 30px;
    //      height: 30px;
    //    }
    //
    //    .notification-size {
    //      width: 16px;
    //      height: 16px;
    //      text-align: center;
    //      border: 2px solid $white;
    //      background-color: $danger;
    //      color: $white;
    //      border-radius: 50%;
    //      font-size: 0.84rem;
    //      font-weight: 600;
    //      position: absolute;
    //      top: 0;
    //      right: 0;
    //      line-height: 1.1rem;
    //    }
    //  }
    //}
  }


  .list-group-item {
    background-color: transparent;
    border: none;
    //margin-left: -.875rem;
  }

}

#body-row {
  margin-left: 0;
  margin-right: 0;
}

#sidebar-container {
  background-color: $white;
  min-height: 100vh;

  .logo {
    text-align: center;
    vertical-align: middle;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: $white;
  }
}

/* Sidebar sizes when expanded and expanded */
.sidebar-expanded {
  max-width: 80vw;
}

/* Menu item*/
#sidebar-container .list-group {
  color: $black;
  background-color: $white;
  border-radius: 0;
  border: none;
  list-style-type: none;
}

.sidebar-item {
  position: relative;
  margin: 0 15px;

  /* Normal item */
  .item-content {
    padding: 13px 20px;

    a {
      color: $black;
    }

    &:hover:not(.close-session),
    &:active:not(.close-session),
    &:focus:not(.close-session) {
      background-color: lighten($primary, 30);
      border-radius: 5px;
    }
  }

  .image-user {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 1px solid $white;
    margin-right: 15px;
    object-fit: cover;
  }

  .icon-item {
    width: 24px;
    height: auto;
    margin-right: 15px;
    color: $black;
    fill: lighten($primary, 15);
    margin-top: -5px;

    &.close-session {
      fill: $success !important;
      height: 22px;
      width: auto;
      margin-left: 2px;
    }
  }

  /* Arrow item */
  &.arrow-item {
    background-color: lighten($primary, 30);
    margin: 10px 0;
    padding: 0 !important;

    .item-content {
      margin: 0;
      padding: 25px 30px;

      &:hover,
      &:active,
      &:focus {
        background: none;

        svg {
          fill: $white;
        }
      }
    }

    .icon-item {
      width: 30px;
      fill: $white;
    }

    .arrow-title {
      text-align: right;
      font-size: 1rem;
      font-weight: 600;
      float: right;
      cursor: pointer;

      .icon-arrow {
        width: 28px;
        height: auto;
        fill: $primary !important;
      }
    }

  }
}

.sidebar-collapsed {
  background-color: green !important;
  //visibility: hidden;
}

/* Submenu item*/
#sidebar-container .list-group .sidebar-submenu li {
  color: $primary;
  border-radius: 0;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background-color: $secondary;
  }
}

.sidebar-submenu {
  font-size: 0.9rem;
  padding-left: 0;
}

.sidebar-submenu-icon {
  padding-left: 2rem;
  float: right;
  position: relative;
  background-color: transparent;
}

.icon-bell {
  font-size: 1.4rem;
}

.user-dropdown-menu {
  right: 180px !important;
  margin-top: 5px;
  position: absolute;
}

.fixed-bar {
  position: absolute;
  z-index: 999;
}

.sidebar-content {
  height: 100%;

  .sidebar-footer {
    bottom: 0;
    color: $secondary !important;

    @media only screen and (min-height: 710px) {
      position: absolute;
    }

    .info {
      margin: 15px 25px;
      font-size: 0.95rem;
    }
  }
}

.main-content {
  overflow: auto;
  overflow-style: auto;
  height: 99%;
  padding-bottom: 3.50rem;
}

.form-inline {
  .form-control {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
  }
}

.link-notifications {
  position: relative;

  .nav-notify-circle {
    color: $accent;
    font-size: 0.55rem;
    border: 1px solid $navbar;
    background-color: $navbar;
    border-radius: 50%;
    position: absolute;
    right: 3px;
  }
}

.main-scrollbar {
  overflow-y: auto;
}

.main-scrollbar-hidden {
  overflow-y: hidden;
}


.screen-container {
  width: 100%;

  .dark-screen {
    background-color: $dark !important;
    filter: $dark (75%);
    z-index: 999;
  }
}

.disabled-screen {
  pointer-events: none;
  opacity: 0.4;
}

//.prueba {
//  background-color: green;
//  z-index: 99999 !important;
//  width: 100%;
//  height: 100%;
//}